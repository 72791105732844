
import { Link } from "react-router-dom";
import { useMediaQuery } from 'usehooks-ts'

function NavBar() {

  const isDesktop = useMediaQuery('(min-width: 768px)'); 


  const navBarFlex = {
    display: 'flex',
    flexDirection: isDesktop ? 'row' : 'column',
  }

  const navStyle = {
    fontSize: isDesktop ? '1.5rem' : '1.1rem'
  }


  return (
    <div>


  <div className="header-box">

      <Link to='/'>
    <img src="images/PianoSeedsLogoOct2023.jpg" alt="Logo" width="60px" />
    </Link>

 
 <div className="website-heading">
    <h1 style={navStyle}>Piano Seeds Teaching Resources</h1>
    <p id="sloganText">Plant a seed. Watch the growth.</p>
    </div>

  </div>
        
        <nav id ="navbox" style={navBarFlex}>
          <Link to='/'>Home</Link>
          <Link to='/about'>About</Link>
          <Link to='/curricula'>Curricula</Link>
          <Link to='/musictheoryinsideout'>Music Theory Inside Out</Link>
          <Link to='/contact'>Contact</Link>
  </nav>

    </div>
  )
}

export default NavBar; 
