import { Link } from "react-router-dom";

function Footer() {
    return (
      <div id="footer-box">
  
      <div className="footer-info" id="footer-links">
      <ul>
      <Link to='/'>Home</Link>
          <li><Link to='/about'>About</Link></li>
          <li><Link to='/curricula'>Curricula</Link></li>
          <li><Link to='/musictheoryinsideout'>Music Theory Inside Out</Link></li>
          <li><Link to='/contact'>Contact</Link></li>
        <li><span>&copy;</span> 2024 All Rights Reserved</li>
      </ul>
      </div>
      
      <div className="footer-info">
       <table>
      <tr>
        <td>Questions? Comments? </td>
      </tr>
      <tr>
        <td>pianoseeds.teachingresources@gmail.com</td>
      </tr>
      <tr>
        <td>Follow Us:</td>
      </tr>
      <tr>
        <td>
          <i className="fa-brands fa-facebook"></i>
        </td>
      </tr>
       </table>
      </div>
      
      
        </div>
    )
  }
  
  
  export default Footer; 
  