import React, { useState, useEffect } from 'react';
import { Pause, PlayArrow } from "@mui/icons-material";
import PropTypes from 'prop-types';

const styles = {
  slideshow: {
    position: 'relative',
    overflow: 'hidden',
    marginTop: '40px',
    width: '100%',
    height: '550px' 

  },
  slideImage: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0px',
    left: '0px', 
    objectFit: 'contain'
  },
  button: {
    backgroundColor: '#FCF8F8', 
    marginTop: '10px',
    marginBottom: '10px',
    borderRadius: '5px', 
    left: '50%',
    padding: '10px 20px',
    fontSize: '10px',
    fontWeight: '600', 
    cursor: 'pointer'
  }

};


function Slideshow({ images, duration }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);

  useEffect(() => {
    let interval;
    if (isPlaying) {
      interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, duration);
    }

    return () => {
        if (interval) {
          clearInterval(interval);
        }
      };
    }, [isPlaying, duration, images.length]);

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const buttonStyles = {
    ...styles.button,
    border: isPlaying ? '2px solid #E37162' : '2px solid #49a396',
    color: isPlaying ? '#E37162' : '#49a396'
  };

  return (
    <>
    <div style={styles.slideshow}>
      {images.map((image, index) => (
        <img
          key={index}
          src={image.url}
          alt={`slide-${index}`}
          style={{
            ...styles.slideImage,
            opacity: currentIndex === index ? 1 : 0,
            transition: 'opacity 0.5s'
          }}
        />
      ))}
      
    </div>
    <button style={buttonStyles} onClick={togglePlayPause}>
    <span>
    {isPlaying ?   <Pause className="svg_icons" />  :   <PlayArrow className="svg_icons" /> }
</span>

      </button>
      </>
    
  );
}

Slideshow.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  duration: PropTypes.number
};

Slideshow.defaultProps = {
  duration: 6000 
};

export default Slideshow;