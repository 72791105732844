import React from 'react';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';


const SingleCurriculumDownload = ({curriculaData}) => {

    useEffect(() => {
        window.scrollTo(0, 0); 
      }, []); 

    const { id } = useParams();
    const curriculum = curriculaData.find(curr => curr.id === id);
  
    if (!curriculum) {
      return <h2>Curriculum not found</h2>;
    }

    return (
      <div id="singleCurrDownload">
          <h2>{curriculum.title}</h2>
          <p>{curriculum.description}</p>
          <h3>Curriculum Objectives: </h3>
          
          <ol>
        {curriculum.objectives.map((objective, index) => (
          <li key={index}>{objective}</li>
        ))}
      </ol>

          <a href={curriculum.pdfUrl} download>Download</a>


      <p>Note: When printing, ensure 'Scale to Fit' is selected for optimal formatting.</p>
      
      </div>
    )
  }
  
  
  export default SingleCurriculumDownload; 
  