
const Contact = () => {
    return (
      <div>
  
  <h1>Under Construction</h1>
  <h2>Email: pianoseeds.teachingresources@gmail.com for any questions/comments/feedback</h2>
        
      </div>
    )
  }
  
  export default Contact; 
  