import React, { useRef } from "react";

import { useEffect, useState } from "react";
import { useMediaQuery } from 'usehooks-ts'; 
import { useLocation } from 'react-router-dom';

import Slideshow from './Slideshow';


const MusicTheoryInsideOut = () => {

  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []); 

  const isDesktop = useMediaQuery('(min-width: 768px)'); 


  const pianoArray = [
    { page: 5, url: "images/theory8.jpg" },
    { page: 6, url: "images/theory5.jpg" },
    { page: 8, url: "images/theory7.jpg" },
    { page: 12, url: "images/theory12.jpg" },
    { page: 20, url: "images/theory6.jpg" },
    { page: 32, url: "images/theory2.jpg" },
    { page: 34, url: "images/theory9.jpg" },
    { page: 35, url: "images/theory13.jpg" },
    { page: 42, url: "images/theory1.jpg" },
    { page: 46, url: "images/theory10.jpg" },
    { page: 61, url: "images/theory4.jpg" },
    { page: 63, url: "images/theory11.jpg" }

];

const location = useLocation();

useEffect(() => {
  const query = new URLSearchParams(location.search);
  const scrollTo = query.get('scrollTo');
  if (scrollTo === 'audioTracks') {
    document.getElementById('listeningtracks-banner').scrollIntoView({ behavior: 'smooth' });
  }
}, [location]);


    return (
      <div id="musictheory-page">

      <h1>Music Theory Inside Out</h1>

      <p className="important-paragraph"><span className="span-bold">Music Theory Inside Out</span> is a theory book for beginning piano students (ages 6-9) that can be
        used alongside <span className="span-underline">any primer lesson book</span>. 
      </p>

      <div id="musictheory-additional-par">

      <p>It is a combination of (1) theory game-based activities, (2) listening assignments and (3) fun, creative recording ideas.</p>

      <p>The required audio tracks are available below as a FREE DOWNLOAD.</p>

      <p>Teachers may prefer to do the listening assignments with students during lesson time. This allows the teacher to guide the activity,
        point at the notation as the audio track plays, and encourage students to be active "participants" in the listening experiences
        (e.g. sing along, pat the steady beat, etc.)
      </p>

      </div>

      <a id="cta-book" href="https://www.amazon.com/dp/B0D5J27SKS?ref_=pe_93986420_774957520" target="_blank">Buy the book on Amazon</a>


     <Slideshow images={pianoArray}/> 


     <div id="listeningtracks-banner">
      {isDesktop && <div id="listeningtrack-banner-desktop">
        <h2>Audio Tracks</h2>
        </div>}

        <a style={{fontWeight: "600", fontSize: "1.25rem"}} href="AllAudioTracks.zip" download>Download ALL Audio Tracks</a>
      <a href="Tracks1-10.zip" download>Download Tracks 1-10</a>
      <a href="Tracks11-20.zip" download>Download Tracks 11-20</a>
      <a href="Tracks21-30.zip" download>Download Tracks 21-30</a>
      <a href="Tracks31-40.zip" download>Download Tracks 31-40</a>
      <a href="Tracks41-50.zip" download>Download Tracks 41-50</a>
      <a href="Tracks51-60.zip" download>Download Tracks 51-60</a>
      <a href="Tracks61-70.zip" download>Download Tracks 61-70</a>
      <a href="Tracks71-80.zip" download>Download Tracks 71-80</a>
      <a href="Tracks81-90.zip" download>Download Tracks 81-90</a>
      <a href="Tracks91-100.zip" download>Download Tracks 91-100</a>
      <a href="Tracks101-110.zip" download>Download Tracks 101-110</a>
      <a href="Tracks111-120.zip" download>Download Tracks 111-120</a>
      <a href="Tracks121-134.zip" download>Download Tracks 121-134</a>
     </div>
     </div>
    )
  }
  
  export default MusicTheoryInsideOut; 
  
