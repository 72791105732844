import {useState, useEffect} from 'react'; 
import SingleCurriculum from "./SingleCurriculum";
import RadioBtn from "./RadioBtn";
import { selectClasses } from '@mui/material';

const Curricula = () => {

  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []); 

  const [subjects, setSubjects] = useState("all");

  const handleSubjectChange = (s) => {
    console.log(subjects); 
    setSubjects(s); 

  }; 

  


    return (
      <div>

        <div id='cirr-banner'>
        <div id='cirr-banner-overlay'></div>
        


<div id='cirr-banner-info'>
<h1>"How To Introduce" Curricula </h1>
  <h4>Search our curricula: </h4>
  <RadioBtn idValue={"all-btn"} label={"All"} 
  onClick = {() => handleSubjectChange('all')} />
  <RadioBtn idValue={"rhythm-btn"} label={"Rhythm"} 
  onClick = {() => handleSubjectChange('rhythm')} />
  <RadioBtn idValue={"melody-btn"} label={"Melody"} 
  onClick = {() => handleSubjectChange('melody')} />
  <RadioBtn idValue={"intervals-btn"} label={"Interval"} 
  onClick = {() => handleSubjectChange('intervals')} />
  <RadioBtn idValue={"other-btn"} label={"Other"} 
  onClick = {() => handleSubjectChange('other')} />
  </div>

</div>


<div id="list-curricula">


  <SingleCurriculum 
  title={"Steady Beat"} 
  imgUrl={"images/currPackageImg/marchingAnts.jpg"} 
  subject = {"rhythm"} 
  style={{ display: subjects === 'rhythm' || subjects === 'all' ? "flex" : "none" }}
  btnText = {"Learn More!"}
  id = {1} /> 

  <SingleCurriculum title={"4/4 Time Signature"} 
  imgUrl={"images/currPackageImg/44Count.jpg"} 
  subject = {"rhythm"} 
  style={{ display: subjects === 'rhythm' || subjects === 'all'  ? "flex" : "none" }}
  btnText = {"Coming Soon!"}
  id = {2} /> 

  <SingleCurriculum title={"Up and Down"} 
  imgUrl={"images/currPackageImg/upDown.jpg"} 
  subject = {"melody"} 
  style={{ display: subjects === 'melody' || subjects === 'all'  ? "flex" : "none" }}
  btnText = {"Coming Soon!"}
  id = {3} /> 

  <SingleCurriculum title={"High and Low"} 
  imgUrl={"images/currPackageImg/highLow.jpg"} 
  subject = {"melody"} 
  style={{ display: subjects === 'melody' || subjects === 'all'  ? "flex" : "none" }}
  btnText = {"Coming Soon!"}
  id = {4} /> 

  <SingleCurriculum title={"Intervals: 3rd, 5th, 7th"} 
  imgUrl={"images/currPackageImg/theory1.jpg"} 
  subject = {"intervals"}
  style={{ display: subjects === 'intervals' || subjects === 'all'  ? "flex" : "none" }}
  btnText = {"Coming Soon!"} 
  id = {5} /> 

  <SingleCurriculum title={"Intervals: 2nd, 4th, 6th"} 
  imgUrl={"images/currPackageImg/theory1.jpg"} 
  subject = {"intervals"} 
  style={{ display: subjects === 'intervals' || subjects === 'all'  ? "flex" : "none" }}
  btnText = {"Coming Soon!"} 
  id = {6}/> 

  <SingleCurriculum title={"Bar Lines"} 
  imgUrl={"images/currPackageImg/greenLightRedLight.jpg"} 
  subject = {"rhythm"} 
  style={{ display: subjects === 'rhythm' || subjects === 'all'  ? "flex" : "none" }}
  btnText = {"Coming Soon!"}
  id = {7} />

    <SingleCurriculum title={"Keyboard Geography"} 
  imgUrl={"images/currPackageImg/keyboard23.jpg"} 
  subject = {"other"} 
  style={{ display: subjects === 'other' || subjects === 'all'  ? "flex" : "none" }}
  btnText = {"Coming Soon!"} 
  id = {8}/> 

  <SingleCurriculum title={"Stepping Notes (Pre-Staff)"} 
  imgUrl={"images/currPackageImg/steppingHouses.jpg"} 
  subject = {"melody"} 
  style={{ display: subjects === 'melody' || subjects === 'all'  ? "flex" : "none" }}
  btnText = {"Coming Soon!"}
  id = {9} /> 

    <SingleCurriculum title={"Skipping Notes (Pre-Staff)"} 
  imgUrl={"images/currPackageImg/skipToMyLou.jpg"} 
  subject = {"melody"} 
  style={{ display: subjects === 'melody' || subjects === 'all'  ? "flex" : "none" }}
  btnText = {"Coming Soon!"}
  id = {10} /> 


<SingleCurriculum title={"Intervals"} 
  imgUrl={"images/currPackageImg/slinkyCat.jpg"} 
  subject = {"melody"} 
  style={{ display: subjects === 'melody' || subjects === 'all'  ? "flex" : "none" }}
  btnText = {"Coming Soon!"}
  id = {11} /> 

<SingleCurriculum title={"Basic Rhythms"} 
  imgUrl={"images/currPackageImg/longshortImg.jpg"} 
  subject = {"rhythm"} 
  style={{ display: subjects === 'rhythm' || subjects === 'all'  ? "flex" : "none" }}
  btnText = {"Coming Soon!"}
  id = {12} /> 

  </div>

        
      </div>
    )
  }
  
  export default Curricula; 
  