import { Link } from "react-router-dom";
import { useEffect } from "react";


const About = () => {

  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []); 
  
    return (
      <div id="about-amy">

        <h1>About Amy</h1>

        <img src="images/Amy.jpg" width={"250px"} height={"250px"}></img>

        <p>Amy is passionate about finding the “best way” to start young students on their musical journey. 
          Although she hasn’t found the answer yet, she has enjoyed over ten rewarding years of trials and discoveries. </p>

          <p>While finding a wealth of fun and age-appropriate games for reviewing, reinforcing and practicing musical concepts, Amy saw a need for game-based learning in the earlier stages of the learning process. 
            Her <Link to="/curricula">“How to Introduce” curriculum</Link> series aims to provide a framework for teachers to prepare and carry out concept-driven lessons. <Link to="/musictheoryinsideout">Music Theory Inside Out</Link>, a theory book, highlights the importance of keeping learning playful & fun, and nurturing students towards becoming more attentive & thoughtful listeners. 
            </p>

        <p>Amy holds a Bachelor of Music degree (Mount Allison) and a Master of Music degree in Piano Performance & Pedagogy (Memorial University of Newfoundland). She has also attended summer programs at the Aspen Music Festival & School and the Adamant Music School. 
          More recently, she became accredited as a Colleague of the Royal College of Organists (London UK). </p>


  
        
      </div>
    )
  }
  
  export default About; 
  