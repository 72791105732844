
const RadioBtn = ({idValue, label, onClick}) => {
  return (
    <div>

<input
        type="radio"
        id={idValue}
        name="subject"
        onClick={onClick}
      />
      <label htmlFor={idValue}>{label}</label>

    </div>
  )
}

export default RadioBtn; 
