import React from 'react';
import { Routes, Route } from 'react-router-dom';
import NavBar from './components/NavBar';
import Home from './components/Home';
import About from './components/About';
import Curricula from './components/Curricula';
import MusicTheoryInsideOut from './components/MusicTheoryInsideOut';
import Contact from './components/Contact';
import Footer from './components/Footer';
import './App.css';
import SingleCurriculumDownload from './components/SingleCurriculumDownload';


function App() {

  const curriculaData = [
    { id: '1', 
    title: 'Steady Beat', 
    description: 'A fun, engaging introduction to one of the most important musical concepts: the steady beat! ', 
    pdfUrl: '/pdf/steadybeat.pdf', 
    objectives: ['Experience the steady beat through a variety of kinesthetic activities.', 
    'Focus on a “marching” beat to help students feel precise, deliberate steady motions.',
    'Enhance students’ understanding of the steady beat by comparing it to unsteady sounds.',
    'Reinforce the steady beat through visual learning: printed visuals and movie clips.',
    'Discover the rhythm syllable ta through play and storytelling.'
] }
  ];

  return (
    <div className="App">
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/curricula" element={<Curricula />} />
        <Route path="/musictheoryinsideout" element={<MusicTheoryInsideOut />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/curriculum/:id" element={<SingleCurriculumDownload curriculaData={curriculaData} />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
