import { Piano, MusicNote, Headphones, EmojiObjects } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useMediaQuery } from 'usehooks-ts'; 


const Home = () => {


  const isDesktop = useMediaQuery('(min-width: 768px)'); 

  const homeBtnStyle = {
    fontSize: "1.25rem",
    backgroundColor: "#845048",
    marginTop: "3%", 
    marginLeft: "40px", 
    padding: "3%", 
    color: "white", 
    border: "none",
    borderRadius: "20px",
    maxWidth: "300px"
  }; 


  const headlineMobileDesktop = {
    display: 'flex',
    flexDirection: isDesktop ? 'row' : 'column'
  }; 

  const headineImageSize = {
    margin: isDesktop ? '50px' : '0px',
    width: isDesktop ? '550px' : '100%'
  }

  const headlineText = {
    margin: '40px' 
  }

  const [currPianoImage, setCurrPianoImage] = useState(0);

  // Piano Rotation Images:
  const pianoArray = [
    { page: 42, url: "images/theory1.jpg" },
    { page: 32, url: "images/theory2.jpg" },
    { page: 46, url: "images/theory3.jpg" },
    { page: 61, url: "images/theory4.jpg" }
];

useEffect(() => {
  const interval = setInterval(() => {
    setCurrPianoImage(prevIndex => (prevIndex + 1) % pianoArray.length);
  }, 4000);

  return () => clearInterval(interval);
  }, []);

  return (
    <div>

      
    <div id="headline" style={headlineMobileDesktop}>

<div className="theory-image" >
<img src={pianoArray[currPianoImage].url} id= "rotating-pages" alt="Theory Book Cover" width="550" style={headineImageSize} />
</div>

<div className="theory-info" style={headlineText}>
  <h2>Music Theory Inside Out</h2>
  <h4 id="heading-h3-theoryinfo">A primer music theory book to correpond with <span className="span-underline">any primer lesson book.</span></h4>
  <h3>A fresh perspective on music theory - focused on listening activities and game-based learning.</h3>

  <div id="cta-btn">
      <Link to='/musictheoryinsideout?scrollTo=learnMore' id="learnMore" style={homeBtnStyle}>Learn More</Link>
      <Link to='/musictheoryinsideout?scrollTo=audioTracks' id="audioTracks" style={homeBtnStyle}>Download Audio Tracks</Link>
    </div>

</div>

</div>

<h2>Plant a Seed. Watch the growth.</h2>

<div id="principle-box">

<div className="principle">
<span className="material-symbols-outlined" style={{fontSize: "60px", fontWeight: "lighter"}}>
  <Piano className="svg_icons" /> 
  </span>
<h3>Fun.</h3>
<p>Music - and learning - should be a fun and joyful activity. Our game-based curricula keep students engaged in the moment and teachers focused on learning objectives. </p>
</div>
<div className="principle">
<span className="material-symbols-outlined" style={{fontSize: "60px", fontWeight: "lighter"}}>
  <MusicNote className="svg_icons" /> 
  </span>
<h3>Creative.</h3>
<p>What do bar lines and green lights have in common? 
  How can a black ant transform into a quarter note? 
  Relatable and creative lesson plans help students become interested in mastering a new skill or understanding a new concept from the very beginning. </p>
</div>
<div className="principle">
<span className="material-symbols-outlined" style={{fontSize: "60px", fontWeight: "lighter"}}>
  <EmojiObjects className="svg_icons" /> 
  </span>
<h3>"Aha"!</h3>
<p>Discovery and exploration are at the heart of memorable learning experiences. 
  Each curriculum has a step-by-step series of activities and games to guide students to their “aha” moment. </p>
</div>
<div className="principle">
<span className="material-symbols-outlined" style={{fontSize: "60px", fontWeight: "lighter"}}>
  <Headphones className="svg_icons" /> 
  </span>
<h3>Concept-Driven.</h3>
<p>Our “How to Introduce” Series compliments any beginning piano method. 
  Ensure lessons stay focused and cohesive during this critical stage in students' musical development. </p>
</div>

</div>

    </div>
  )
}

export default Home; 