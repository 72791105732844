
import { Link } from "react-router-dom";

const SingleCurriculum = ({title, imgUrl, subject, style, btnText, id}) => {

  return (
    <div id="singleCurr" style={style}>
        <h2>{title}</h2>
        <img src = {imgUrl} width={"225px"} height={"200px"} style={{objectFit: "cover"}}  /> 
        
        {btnText === "Coming Soon!" ? (
        <p>{btnText}</p>
      ) : (
        <Link to={`/curriculum/${id}`}>
          <button>{btnText}</button>
        </Link>
      )}

    </div>
  )
}


export default SingleCurriculum; 
